var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"headers":_vm.cols,"items":_vm.products,"custom-sort":_vm.prevent_sort,"not_resizeble":"","no-data-text":"Заказы, удовлетворяющие выбранным параметрам, отсутствуют"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.products.indexOf(item) + 1)+" ")]}},{key:"item.nomenclature",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.product ? item.product.nomenclature : '')+" "),_c('br'),_c('span',{class:_vm.$style.cell_small},[_vm._v(" Код товара "+_vm._s(item.product ? item.product.product_code : '')+" ")])])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.quantity)+" ")])]}},{key:"item.params",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.weight ? item.weight + " кг" : '')+" "),_c('br'),_vm._v(" "+_vm._s(item.volume)+" ")])]}},{key:"item.delivery_type",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.delivery_name(item))+" ")])]}},{key:"item.transport_company",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.trasnport_company_name(item))+" ")])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.delivery_address(item))+" "),_c('br'),_c('br')])]}},{key:"item.shipment_date",fn:function(){return [_c('td',{staticClass:"py-2"},[_vm._v(_vm._s(_vm._f("date")(_vm.order_details.shipment_date))+" "),_c('br')])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }